<template>
  <v-container justify-center fluid grid-list-xl>
    <v-layout justify-center wrap>
      <v-flex md12>
        <messageBox ref="msgBox" />

        <vehicleEditor
          @vehicleConfigured="handleVehicle"
          v-model="vehicleEditorDialog"
          ref="vehicleEditor"
        />

        <v-dialog
          overlay-opacity="0.6"
          max-width="600"
          :value="changeDriverDialog"
          persistent
        >
          <v-container fill-height fluid grid-list-xl>
            <v-layout justify-center wrap>
              <v-flex md12>
                <base-material-card color="primary">
                  <template v-slot:heading>
                    <v-row class="pa-0">
                      <v-col cols="1" class="pa-0"
                        ><v-icon large>mdi-account</v-icon></v-col
                      >
                      <v-col
                        align="center"
                        cols="10"
                        class="pa-0 card-title-text"
                        style="font-size: 25px"
                        >{{ $t("vehiclesmng-manager-driver-title") }}</v-col
                      >
                      <v-col cols="1" class="pa-0"
                        ><v-icon @click="changeDriverDialog = false" large
                          >mdi-close</v-icon
                        ></v-col
                      >
                    </v-row>
                  </template>
                  <div style="height:10px" />
                  <v-select
                    :label="$t('vehiclesmng-manager-hint-courier')"
                    item-text="name"
                    item-value="id"
                    outlined
                    dense
                    v-model="changeDriverValue"
                    :items="couriers"
                  />
                  <v-btn @click="handleChangeDriver()" color="primary">{{
                    $t("gbl-ok")
                  }}</v-btn>
                </base-material-card>
              </v-flex>
            </v-layout>
          </v-container>
        </v-dialog>

        <v-progress-circular
          v-if="loadingData"
          :indeterminate="true"
          :rotate="0"
          :size="32"
          :width="4"
          color="primary"
        ></v-progress-circular>

        <v-btn
          v-if="!loadingData && $store.state.hasRight('164')"
          v-on:click="addVehicle(0)"
          color="info"
          >{{ $t("vehiclesmng-add-vehicle") }}</v-btn
        >

        <base-material-card
          v-if="!loadingData"
          color="primary"
          icon="mdi-package-variant-closed"
          inline
          class="px-5 py-3"
        >
          <template v-slot:after-heading>
            <div class="display-1 font-weight-light card-header">
              {{ $t("vehiclesmng-title") }}
            </div>
          </template>
          <div style="height:10px" />
          <v-row dense>
            <v-col>
              <v-text-field
                v-model="filter"
                append-icon="mdi-magnify"
                class="mr-auto pl-1"
                :label="$t('gbl-search')"
                hide-details
                single-line
                style="max-width: 250px;"
              />
            </v-col>
          </v-row>

          <div class="vertical-spacer" />

          <v-data-table
            class="grid-table"
            :items-per-page="perPage"
            :must-sort="true"
            :headers="headers"
            :items="vehicles"
            :options.sync="options"
            :server-items-length="totalVehicles"
            :loading="loadingVehicles"
            :footer-props="dataTableFooterOptions"
          >
            <template v-slot:item.actions="{ item }">
              <v-row
                ><v-col class="text-right" style="min-width:64px;  ">
                  <v-tooltip v-if="$store.state.hasRight('172')" bottom
                    ><template v-slot:activator="{ on }"
                      ><v-btn
                        x-small
                        text
                        icon
                        color="primary"
                        @click="editItem(item)"
                        v-on="on"
                        ><v-icon>mdi-pencil</v-icon></v-btn
                      ></template
                    ><span>{{
                      $t("vehiclesmng-modify-vehicle")
                    }}</span></v-tooltip
                  >
                  <v-tooltip v-if="$store.state.hasRight('171')" bottom
                    ><template v-slot:activator="{ on }"
                      ><v-btn
                        x-small
                        text
                        icon
                        color="error"
                        @click="deleteItem(item)"
                        v-on="on"
                        ><v-icon>mdi-delete</v-icon></v-btn
                      ></template
                    ><span>{{
                      $t("vehiclesmng-remove-vehicle")
                    }}</span></v-tooltip
                  >
                </v-col></v-row
              ></template
            >

            <template v-slot:item.current_driver="{ item }">
              {{
                !item.driver_id ? $t("vehiclesmng-no-driver") : item.driver_name
              }}
              <v-tooltip v-if="$store.state.hasRight('229')" bottom
                ><template v-slot:activator="{ on }"
                  ><v-btn
                    x-small
                    text
                    icon
                    color="orange darken-2"
                    @click="setDriver(item)"
                    v-on="on"
                    ><v-icon>mdi-pencil</v-icon></v-btn
                  ></template
                ><span>{{ $t("vehiclesmng-change-driver") }}</span></v-tooltip
              >
            </template>
          </v-data-table>
        </base-material-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import staticData from "@/apis/static";
import usersManager from "@/apis/users";
import messageBox from "@/components/MessageBox";
import vehiclesManager from "@/apis/vehicles";
import vehicleEditor from "@/components/VehicleEditor";

export default {
  data() {
    return {
      options: {
        sortBy: ["name"],
        sortDesc: [false]
      },
      dataTableFooterOptions: staticData.dataTableFooterOptions,

      headers: [],
      perPage: staticData.defautPerPage,

      couriers: [],
      changeDriverDialog: false,
      changeDriverValue: null,
      changeDriverItem: null,

      filter: "",

      vehicles: [],
      totalVehicles: 0,
      loadingVehicles: false,
      loadingData: false,
      vehicleEditorDialog: false
    };
  },

  mounted() {
    this.headers = [
      {
        text: this.$t("vehiclesmng-tbl-plate"),
        align: "left",
        sortable: true,
        value: "plate"
      },
      {
        text: this.$t("vehiclesmng-tbl-name"),
        align: "left",
        sortable: true,
        value: "name"
      },
      {
        text: this.$t("vehiclesmng-tbl-description"),
        align: "left",
        sortable: true,
        value: "description"
      },

      {
        text: this.$t("vehiclesmng-tbl-currentdriver"),
        align: "left",
        sortable: true,
        value: "current_driver"
      },
      {
        text: this.$t("vehiclesmng-tbl-extra"),
        align: "left",
        sortable: false,
        value: "notes"
      },
      { value: "actions", sortable: false, text: "", width: "64px" }
    ];
  },

  components: {
    messageBox,
    vehicleEditor
  },

  watch: {
    options: {
      handler() {
        this.fetchVehicles();
      },
      deep: true
    },

    filter: {
      handler() {
        clearTimeout(this._debounceTimer);
        this._debounceTimer = setTimeout(() => {
          if (this.options.page != 1) this.options.page = 1;
          else this.fetchVehicles();
        }, 300);
      }
    }
  },

  methods: {
    setDriver(item) {
      this.fetchingCouriers = true;
      this.$store.state.global_overlay = true;
      this.$store.state.global_overlay_text = this.$t(
        "ordersmng-fetching-couriers"
      );
      usersManager
        .fetchCouriers()
        .then(res => {
          this.changeDriverItem = item;
          this.changeDriverValue = item.driver_id;
          this.couriers = [
            {
              id: null,
              name: this.$t("gbl-none-male"),
              first_name: this.$t("gbl-none-male"),
              last_name: this.$t("gbl-none-male")
            },
            ...res.items
          ];
          this.fetchingCouriers = false;
          this.$store.state.global_overlay = false;
          this.changeDriverDialog = true;
        })
        .catch(err => {
          this.fetchingCouriers = false;
          this.$store.state.global_overlay = false;
          console.log(err);
        });
    },

    fetchVehicles() {
      this.loadingVehiclesù = true;
      this.totalVehicles = 0;
      return new Promise((resolve, reject) => {
        let ctx = Object.assign({}, this.options);
        ctx.currentPage = ctx.page;
        ctx.perPage = ctx.itemsPerPage != -1 ? ctx.itemsPerPage : null;
        ctx.filter = this.filter;
        ctx.sortBy = ctx.sortBy[0];
        ctx.sortDesc = ctx.sortDesc[0];
        vehiclesManager
          .fetchVehicles(ctx)
          .then(result => {
            this.totalVehicles = result.totalCount;
            this.vehicles = result.items;
            this.loadingVehicles = false;
            resolve(result);
          })
          .catch(err => {
            this.loadingVehicles = false;
            console.log(err);
            reject(err);
          });
      });
    },

    handleChangeDriver() {
      vehiclesManager
        .changeDriver(this.changeDriverItem.id, this.changeDriverValue)
        .then(() => {
          this.changeDriverItem.driver_id = this.changeDriverValue;
          this.changeDriverItem = null;
          this.changeDriverValue = null;
          this.changeDriverDialog = false;
          this.fetchVehicles();
        })
        .catch(err => {
          console.log(err);
          this.changeDriverDialog = false;
          this.changeDriverItem = null;
          this.changeDriverValue = null;
        });
    },

    handleVehicle(event) {
      if (event.editingVehicle) {
        let vehicle = event.vehicle;
        vehiclesManager
          .updateVehicle(vehicle)
          .then(() => {
            this.fetchVehicles();
          })
          .catch(err => {
            console.log(err);
          });
      } else {
        let vehicle = event.vehicle;
        vehiclesManager
          .addVehicle(vehicle)
          .then(() => {
            this.fetchVehicles();
          })
          .catch(err => {
            console.log(err);
          });
      }
      this.vehicleEditorDialog = false;
    },

    deleteItem(item) {
      let vehicleId = item.id;
      this.$refs.msgBox
        .show(
          this.$t("vehiclesmng-delete-title"),
          this.$t("vehiclesmng-delete-text")
        )
        .then(() => {
          vehiclesManager
            .removeVehicle(vehicleId)
            .then(() => {
              this.fetchVehicles();
            })
            .catch(err => {
              console.log(err);
            });
        })
        .catch(() => {});
    },

    editItem(item) {
      let vehicle = JSON.parse(JSON.stringify(item));
      this.$refs.vehicleEditor.setModalVehicle(vehicle, true);
      this.vehicleEditorDialog = true;
    },

    addVehicle() {
      vehiclesManager.fetchNextId().then(res => {
        this.$refs.vehicleEditor.setModalVehicle(
          {
            id: res.nextId,
            name: "",
            plate: "",
            extra: ""
          },
          false
        );
        this.vehicleEditorDialog = true;
      });
    }
  }
};
</script>
