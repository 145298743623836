<template>
  <v-dialog persistent overlay-opacity="0.6" max-width="800" :value="value">
    <!-- @input is required to emit emit input if clicked outside unless we use persistent -->
    <v-container fill-height fluid grid-list-xl>
      <v-layout justify-center wrap>
        <v-flex md12>
          <base-material-card class="pa-0" color="primary">
            <template v-slot:heading>
              <v-row class="pa-0">
                <v-col cols="1" class="pa-0"
                  ><v-icon large>mdi-car</v-icon></v-col
                >
                <v-col
                  align="center"
                  cols="10"
                  class="pa-0 card-title-text"
                  style="font-size: 25px"
                  >{{ $t("vehicle-edit-title") }}</v-col
                >
                <v-col cols="1" class="pa-0 text-right"
                  ><v-icon @click="closeDialog" large>mdi-close</v-icon></v-col
                >
              </v-row>
            </template>
            <div v-if="fetching">
              <div style="height:10px" />
              <v-progress-circular
                :enableExif="true"
                :indeterminate="true"
                :rotate="0"
                :size="32"
                :width="4"
                color="primary"
              ></v-progress-circular>
            </div>
            <div style="height:20px" />
            <ValidationObserver
              v-if="!fetching"
              ref="obs"
              v-slot="{ invalid, validated }"
            >
              <v-row dense>
                <v-col>
                  <ValidationProvider
                    :immediate="editingVehicle"
                    rules="required"
                    v-slot="{ errors, valid }"
                  >
                    <v-text-field
                      v-model="vehicle.name"
                      :label="$t('vehicle-edit-hint-name')"
                      :error-messages="errors"
                      :success="valid"
                      required
                      outlined
                      dense
                    ></v-text-field>
                  </ValidationProvider>
                </v-col>
                <v-col>
                  <ValidationProvider
                    :immediate="editingVehicle"
                    rules="required"
                    v-slot="{ errors, valid }"
                  >
                    <v-text-field
                      v-model="vehicle.plate"
                      :label="$t('vehicle-edit-hint-plate')"
                      outlined
                      dense
                      :error-messages="errors"
                      :success="valid"
                    ></v-text-field
                  ></ValidationProvider>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col>
                  <v-text-field
                    v-model="vehicle.description"
                    :label="$t('vehicle-edit-hint-description')"
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row dense>
                <v-col>
                  <v-text-field
                    v-model="vehicle.notes"
                    :label="$t('vehicle-edit-hint-notes')"
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row dense>
                <v-col>
                  <v-btn
                    width="120"
                    :disabled="invalid || !validated"
                    v-on:click="confirm()"
                    color="primary"
                    >{{ $t("gbl-ok") }}</v-btn
                  >
                  <v-btn width="120" v-on:click="cancel()" color="secondary">{{
                    $t("gbl-cancel")
                  }}</v-btn>
                </v-col>
              </v-row>
            </ValidationObserver>
          </base-material-card>
        </v-flex>
      </v-layout>
    </v-container>
  </v-dialog>
</template>

<script>
import { ValidationObserver, ValidationProvider } from "vee-validate";

export default {
  data() {
    return {
      vehicle: {},
      editingVehicle: false,

      fetching: false
    };
  },

  props: ["value"],

  components: {
    ValidationObserver,
    ValidationProvider
  },

  methods: {
    confirm: function() {
      this.handleSubmit();
    },

    cancel: function() {
      this.$emit("input"); // Input signal emitted from a dialog closes it, not clear in the documentation
    },

    closeDialog: function() {
      this.cancel();
    },

    handleSubmit() {
      this.$emit("vehicleConfigured", {
        editingVehicle: this.editingVehicle,
        vehicle: this.vehicle
      });
      this.$nextTick(() => {
        this.$emit("input");
      });
    },

    setModalVehicle(vehicle, editing) {
      this.vehicle = Object.assign({}, vehicle);
      this.editingVehicle = editing;
    }
  }
};
</script>
