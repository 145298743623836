<template>
  <v-container justify-center fluid grid-list-xl>
    <messageBox ref="msgBox" />
    <mailingEditor
      @mailingConfigured="handleConfiguration"
      v-model="mailingEditorDialog"
      ref="mailingEditor"
    />
    <v-layout justify-center wrap>
      <div style="height:10px;"></div>
      <v-btn
        v-if="$store.state.hasRight('218')"
        color="primary"
        v-on:click="addCampaign()"
        >{{ $t("mailmng-add") }}</v-btn
      >
      <v-flex md12>
        <base-material-card
          color="primary"
          icon="mdi-mail"
          inline
          class="px-5 py-3"
        >
          <template v-slot:after-heading>
            <div class="display-1 font-weight-light card-header">
              {{ $t("mailmng-title") }}
            </div>
          </template>
          <v-divider class="mt-3" />
          <v-data-table
            :headers="headers"
            :items="mailings"
            :options.sync="options"
            :server-items-length="totalMailings"
            :loading="loading"
            hide-default-footer
          >
            <template v-slot:item.status="{ item }">
              {{
                item.status == 0
                  ? $t("mailmng-paused")
                  : item.status == 1
                  ? $t("mailmng-started")
                  : $t("mailmng-completed")
              }}
            </template>

            <template v-slot:item.actions="{ item }">
              <v-tooltip
                v-if="$store.state.hasRight('220') && item.status == 0"
                bottom
                ><template v-slot:activator="{ on }"
                  ><v-btn
                    x-small
                    text
                    icon
                    color="primary"
                    @click.stop="editCampaign(item)"
                    v-on="on"
                    ><v-icon>mdi-pencil</v-icon></v-btn
                  ></template
                ><span>{{ $t("mailmng-duplicate") }}</span></v-tooltip
              >
              <v-tooltip v-if="$store.state.hasRight('221')" bottom
                ><template v-slot:activator="{ on }"
                  ><v-btn
                    x-small
                    text
                    icon
                    color="primary"
                    @click.stop="duplicateCampaign(item)"
                    v-on="on"
                    ><v-icon>mdi-content-duplicate</v-icon></v-btn
                  ></template
                ><span>{{ $t("mailmng-duplicate") }}</span></v-tooltip
              >
              <v-tooltip
                v-if="$store.state.hasRight('222') && item.status == 0"
                bottom
                ><template v-slot:activator="{ on }"
                  ><v-btn
                    x-small
                    text
                    icon
                    color="green"
                    @click.stop="startCampaign(item)"
                    v-on="on"
                    ><v-icon>mdi-play</v-icon></v-btn
                  ></template
                ><span>{{ $t("mailmng-start") }}</span></v-tooltip
              >
              <v-tooltip
                v-if="$store.state.hasRight('223') && item.status == 1"
                bottom
                ><template v-slot:activator="{ on }"
                  ><v-btn
                    x-small
                    text
                    icon
                    color="orange"
                    @click.stop="pauseCampaign(item)"
                    v-on="on"
                    ><v-icon>mdi-pause</v-icon></v-btn
                  ></template
                ><span>{{ $t("mailmng-pause") }}</span></v-tooltip
              >
              <v-tooltip v-if="$store.state.hasRight('219')" bottom
                ><template v-slot:activator="{ on }"
                  ><v-btn
                    x-small
                    text
                    icon
                    color="red"
                    @click.stop="deleteItem(item)"
                    v-on="on"
                    ><v-icon>mdi-delete-circle-outline</v-icon></v-btn
                  ></template
                ><span>{{ $t("mailmng-remove") }}</span></v-tooltip
              >
            </template>
          </v-data-table>
        </base-material-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import staticData from "../apis/static";
import mailingManager from "../apis/mailing";

import messageBox from "../components/MessageBox";
import mailingEditor from "../components/MailingEditor";

export default {
  data() {
    return {
      loading: false,

      options: {
        sortBy: ["id"],
        sortDesc: [false],
        itemsPerPage: 50,
        page: 1,
      },

      headers: [
        {
          text: this.$t("mailmng-tbl-name"),
          align: "left",
          sortable: true,
          value: "name",
        },

        {
          text: this.$t("mailmng-tbl-status"),
          align: "center",
          sortable: true,
          value: "status",
        },

        {
          text: this.$t("mailmng-tbl-total"),
          align: "center",
          sortable: true,
          value: "mail_counts",
        },
        {
          text: this.$t("mailmng-tbl-pending"),
          align: "center",
          sortable: true,
          value: "pending_mails",
        },
        {
          text: this.$t("mailmng-tbl-sent"),
          align: "center",
          sortable: true,
          value: "sent_mails",
        },

        {
          text: this.$t("mailmng-tbl-delivered"),
          align: "center",
          sortable: true,
          value: "delivered_mails",
        },
        {
          text: this.$t("mailmng-tbl-not-delivered"),
          align: "center",
          sortable: true,
          value: "error_mails",
        },
        { value: "actions", sortable: false, text: "" },
      ],

      mailings: [],
      totalMailings: 0,

      currentPage: 1,
      perPage: staticData.defaultPerPage,
      sortDesc: false,

      mailingEditorDialog: null,
    };
  },

  components: {
    messageBox,
    mailingEditor,
  },

  watch: {
    options: {
      handler() {
        this.fetchMailings();
      },
      deep: true,
    },
  },

  methods: {
    fetchMailings() {
      this.loading = true;
      this.mailings = [];
      this.totalMailings = 0;
      let ctx = Object.assign({}, this.options);
      ctx.currentPage = ctx.page;
      ctx.perPage = ctx.itemsPerPage != -1 ? ctx.itemsPerPage : null;
      ctx.filter = this.filter;
      ctx.sortBy = ctx.sortBy[0];
      ctx.sortDesc = ctx.sortDesc[0];
      mailingManager
        .fetchMailings(ctx)
        .then((result) => {
          this.totalMailings = result.count;
          this.mailings = result.mailings;
          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
        });
    },

    addCampaign() {
      this.$refs.mailingEditor.setModalMailing({}, false);
      this.mailingEditorDialog = true;
    },

    editCampaign(item) {
      this.$refs.mailingEditor.setModalMailing(item, true);
      this.mailingEditorDialog = true;
    },

    startCampaign(item) {
      mailingManager
        .startCampaign(item.id)
        .then(() => {
          this.fetchMailings();
        })
        .catch((err) => {
          console.log(err);
        });
    },

    pauseCampaign(item) {
      mailingManager
        .pauseCampaign(item.id)
        .then(() => {
          this.fetchMailings();
        })
        .catch((err) => {
          console.log(err);
        });
    },

    duplicateCampaign(item) {
      mailingManager
        .duplicateCampaign(item.id)
        .then(() => {
          this.fetchMailings();
        })
        .catch((err) => {
          console.log(err);
        });
    },

    handleConfiguration(item) {
      if (!item.editingMailing) {
        mailingManager
          .addMailing(item.mailing)
          .then(() => {
            this.fetchMailings();
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        mailingManager
          .updateMailing(item.mailing)
          .then(() => {
            this.fetchMailings();
          })
          .catch((err) => {
            console.log(err);
          });
      }

      this.mailingEditorDialog = false;
    },

    deleteItem(item) {
      this.$refs.msgBox
        .show(
          this.$t("mailmng-msgbox-delete-text"),
          this.$t("mailmng-msgbox-delete-title")
        )
        .then(() => {
          mailingManager
            .removeMailing(item.id)
            .then(() => {
              this.fetchMailings();
            })
            .catch((err) => {
              console.log(err);
            });
        })
        .catch(() => {});
    },
  },
};
</script>
