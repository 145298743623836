<template>
  <v-dialog overlay-opacity="0.6" persistent max-width="1024" :value="value">
    <!-- @input is required to emit emit input if clicked outside unless we use persistent -->
    <v-container fill-height fluid grid-list-xl>
      <v-layout justify-center wrap>
        <v-flex md12>
          <base-material-card class="pa-0" color="primary">
            <template v-slot:heading>
              <v-row class="pa-0">
                <v-col cols="1" class="pa-0"
                  ><v-icon large>mdi-mail</v-icon></v-col
                >
                <v-col
                  align="center"
                  cols="10"
                  class="pa-0 card-title-text"
                  style="font-size: 25px"
                  >{{
                    editingMailing
                      ? $t("mailing-edit-modify")
                      : $t("mailing-edit-add")
                  }}</v-col
                >
                <v-col cols="1" class="pa-0 text-right"
                  ><v-icon @click="closeDialog" large>mdi-close</v-icon></v-col
                >
              </v-row>
            </template>

            <div class="vertical-spacer" />
            <ValidationObserver ref="obs" v-slot="{ invalid, validated }">
              <v-row dense>
                <v-col
                  ><ValidationProvider
                    rules="required"
                    :immediate="true"
                    v-slot="{ errors, valid }"
                  >
                    <v-text-field
                      outlined
                      dense
                      v-model="mailing.name"
                      :label="$t('mailing-edit-hint-name')"
                      :error-messages="errors"
                      :success="valid"
                      required
                    ></v-text-field>
                  </ValidationProvider>
                </v-col>
                <v-col>
                  <v-select
                    outlined
                    dense
                    v-model="mailing.type"
                    :label="$t('mailing-edit-hint-type')"
                    item-text="name"
                    item-value="id"
                    :items="types"
                  ></v-select>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col>
                  <v-select
                    outlined
                    dense
                    v-model="mailing.target"
                    :label="$t('mailing-edit-hint-targets')"
                    item-text="name"
                    item-value="id"
                    :items="targets"
                  ></v-select>
                </v-col>
                <v-col v-if="mailing.target == 0">
                  <ValidationProvider
                    :immediate="true"
                    rules="required|email"
                    v-slot="{ errors, valid }"
                  >
                    <v-text-field
                      outlined
                      dense
                      v-model="mailing.recipient"
                      :label="$t('mailing-edit-hint-recipients')"
                      :error-messages="errors"
                      :success="valid"
                      required
                    ></v-text-field>
                  </ValidationProvider>
                </v-col>
                <v-col v-if="mailing.target == 1">
                  <ValidationProvider
                    rules="required"
                    v-slot="{ errors, valid }"
                  >
                    <v-select
                      outlined
                      dense
                      v-model="mailing.category"
                      :label="$t('mailing-edit-hint-category')"
                      item-text="name"
                      item-value="id"
                      required
                      :items="categories"
                    ></v-select>
                  </ValidationProvider>
                </v-col>
                <v-col v-if="mailing.target == 2">
                  <ValidationProvider
                    rules="required"
                    v-slot="{ errors, valid }"
                  >
                    <v-text-field
                      v-model="mailing.color"
                      readonly
                      hide-details
                      class="ma-0 pa-0"
                      outlined
                      dense
                      :label="$t('customer-editor-hint-color')"
                    >
                      <template v-slot:append>
                        <v-menu
                          v-model="menu"
                          top
                          nudge-bottom="105"
                          nudge-left="16"
                          :close-on-content-click="false"
                        >
                          <template v-slot:activator="{ on }">
                            <div :style="swatchStyle" v-on="on" />
                          </template>
                          <v-card>
                            <v-card-text class="pa-0">
                              <v-color-picker v-model="mailing.color" flat />
                            </v-card-text>
                          </v-card>
                        </v-menu>
                      </template>
                    </v-text-field>
                  </ValidationProvider>
                </v-col>
              </v-row>

              <v-row dense>
                <v-col>
                  <ValidationProvider
                    rules="required"
                    v-slot="{ errors, valid }"
                  >
                    <v-text-field
                      outlined
                      dense
                      v-model="mailing.subject"
                      :label="$t('mailing-edit-hint-subject')"
                      :error-messages="errors"
                      :success="valid"
                      required
                      :immediate="true"
                    ></v-text-field>
                  </ValidationProvider>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col>
                  <quill-editor
                    ref="myQuillEditor"
                    v-model="mailing.content"
                    :options="editorOption"
                  />
                </v-col>
              </v-row>
              <v-row dense>
                <v-col>
                  <v-btn
                    width="120"
                    :disabled="invalid || !validated"
                    v-on:click="confirm()"
                    color="orange"
                    >{{ $t("gbl-ok") }}</v-btn
                  >
                  <v-btn width="120" v-on:click="cancel()" color="primary">{{
                    $t("gbl-cancel")
                  }}</v-btn>
                </v-col>
              </v-row>
            </ValidationObserver>
          </base-material-card>
        </v-flex>
      </v-layout>
    </v-container>
  </v-dialog>
</template>

<script>
import customersManager from "../apis/customers";

import { ValidationObserver, ValidationProvider } from "vee-validate";

import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";

import { quillEditor } from "vue-quill-editor";

export default {
  data() {
    return {
      mailing: {
        name: "",
        content: "",
        type: 0,
        target: 0,
        recipient: "",
        subject: "",
        category: null,
        color: "#000000ff",
      },

      types: [],
      targets: [],
      categories: [],
      editingMailing: false,
      menu: null,
      editorOption: {
        modules: {
          toolbar: ["bold", "italic", "underline", "strike", "link"],
        },
      },
    };
  },

  components: {
    ValidationProvider,
    ValidationObserver,
    quillEditor,
  },

  props: ["value"],

  mounted() {
    customersManager
      .fetchCategories()
      .then((res) => {
        this.categories = res.items;
      })
      .catch((err) => {
        console.log(err);
      });

    this.colorPickerColor = this.mailing.color;
    this.targets = [
      { id: 0, name: this.$t("mailing-target-email") },
      { id: 1, name: this.$t("mailing-target-category") },
      { id: 2, name: this.$t("mailing-target-color") },
      { id: 3, name: this.$t("mailing-target-all") },
    ];

    this.types = [
      { id: 0, name: this.$t("mailing-target-type-normal") },
      { id: 1, name: this.$t("mailing-target-type-pec") },
    ];
  },

  computed: {
    swatchStyle() {
      return {
        backgroundColor: this.mailing.color,
        cursor: "pointer",
        height: "25px",
        width: "25px",
        borderRadius: "4px",
        transition: "border-radius 200ms ease-in-out",
      };
    },
  },

  methods: {
    confirm: function() {
      this.$emit("mailingConfigured", {
        editingMailing: this.editingMailing,
        mailing: this.mailing,
      });
      this.$refs.obs.reset();
    },

    closeDialog: function() {
      this.cancel();
    },

    cancel: function() {
      this.$emit("input"); // Input signal emitted from a dialog closes it, not clear in the documentation
    },

    resetModal() {
      this.mailing.content = "";
      this.mailing.name = "";
      this.mailing.type = 0;
      this.mailing.target = 0;
      this.mailing.recipient = "";
      this.mailing.subject = "";
      this.mailing.category = this.categories[0] ? this.categories[0].id : null;
      this.mailing.color = "#000000ff";
    },

    setModalMailing(mailing, editing) {
      this.editingMailing = editing;
      if (editing) {
        this.mailing = Object.assign({}, mailing);
      } else {
        this.resetModal();
      }

      if (editing) {
        requestAnimationFrame(() => {
          if (this.$refs.obs) {
            this.$refs.obs.validate();
          }
        });
      } else {
        requestAnimationFrame(() => {
          if (this.$refs.obs) this.$refs.obs.reset();
        });
      }
    },
  },
};
</script>
