import axios from "axios";
import config from "../config";

let mailings = {
  startCampaign: (id) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/mailing/start",
        data: { id: id },
        method: "POST",
      })
        .then(() => {
          resolve();
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  pauseCampaign: (id) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/mailing/pause",
        data: { id: id },
        method: "POST",
      })
        .then(() => {
          resolve();
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  duplicateCampaign: (id) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/mailing/duplicate",
        data: { id: id },
        method: "POST",
      })
        .then(() => {
          resolve();
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  fetchMailings: (ctx) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/mailing/list",
        data: { ctx: ctx },
        method: "POST",
      })
        .then((resp) => {
          resolve({
            mailings: resp.data.responseData.mailings,
            count: resp.data.responseData.count,
          });
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  addMailing: (mailing) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/mailing/add",
        data: { mailing: mailing },
        method: "POST",
      })
        .then((resp) => {
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  updateMailing: (mailing) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/mailing/update",
        data: { mailing: mailing },
        method: "POST",
      })
        .then((resp) => {
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  removeMailing: (id) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/mailing/delete",
        data: { id: id },
        method: "POST",
      })
        .then((resp) => {
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};

export default mailings;
