<template>
  <v-container justify-center fluid grid-list-xl>
    <v-layout justify-center wrap>
      <v-flex md12>
        <v-progress-circular
          v-if="loadingFilters"
          :indeterminate="true"
          :rotate="0"
          :size="32"
          :width="4"
          color="primary"
        ></v-progress-circular>

        <FiltersBar
          :filters="filters"
          :disabled="loadingVehicles"
          @onFiltersLoading="onFiltersLoading"
          @onFiltersLoaded="onFiltersLoaded"
          :showLoader="true"
          :VehiclesHistoryFilter="true"
        />

        <base-material-card
          v-if="!loadingFilters"
          color="primary"
          icon="mdi-package-variant-closed"
          inline
          class="px-5 py-3"
        >
          <template v-slot:after-heading>
            <div class="display-1 font-weight-light card-header">
              {{ $t("vehicleshistory-title") }}
            </div>
          </template>
          <div style="height:10px" />

          <div class="vertical-spacer" />

          <v-data-table
            class="grid-table"
            :items-per-page="perPage"
            :must-sort="true"
            :headers="headers"
            :items="vehicles"
            :options.sync="options"
            :server-items-length="totalVehicles"
            :loading="loadingVehicles"
            :footer-props="dataTableFooterOptions"
          >
            <template v-slot:item.driver_name="{ item }">
              <v-icon color="red" v-if="item.driver_name">mdi-circle</v-icon>
              <v-icon color="green" v-if="!item.driver_name">mdi-circle</v-icon>
              {{
                item.driver_name
                  ? item.driver_name
                  : $t("vehicleshistory-tbl-released")
              }}
            </template>
            <template v-slot:item.event_time="{ item }">
              {{ item.event_time | toLocaleDateTime }}
            </template>

            <template v-slot:item.event_user_name="{ item }">
              <v-avatar
                size="36px"
                v-if="checkGravatar(item) && getGravatar(item)"
              >
                <v-img :src="getGravatar(item)" :alt="getUsername(item)" />
              </v-avatar>

              <v-avatar
                v-else-if="getProfileLetters(item)"
                color="blue"
                size="36px"
              >
                <span class="profile-text">{{ getProfileLetters(item) }}</span>
              </v-avatar>

              {{ item.event_user_name }}
            </template>
          </v-data-table>
        </base-material-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import staticData from "@/apis/static";
import vehiclesManager from "@/apis/vehicles";
import AvatarServices from "@/services/avatars";
import FiltersBar from "@/components/FiltersBar";

export default {
  data() {
    return {
      options: {
        sortBy: ["name"],
        sortDesc: [false]
      },
      dataTableFooterOptions: staticData.dataTableFooterOptions,

      headers: [],
      perPage: staticData.defautPerPage,

      filter: "",

      vehicles: [],
      totalVehicles: 0,
      loadingVehicles: false,
      loadingFilters: false,
      filters: {
        filterByUserId: null,
        filterByCourierId: null,
        filterByPeriod: 11,
        filterByCustomDateStart: null,
        filterByCustomDateEnd: null,
        filterByVehicleId: null
      }
    };
  },

  mounted() {
    this.headers = [
      {
        text: this.$t("vehicleshistory-tbl-name"),
        align: "left",
        sortable: true,
        value: "event_user_name"
      },
      {
        text: this.$t("vehicleshistory-tbl-plate"),
        align: "left",
        sortable: true,
        value: "plate"
      },
      {
        text: this.$t("vehicleshistory-tbl-time"),
        align: "left",
        sortable: false,
        value: "event_time"
      },
      {
        text: this.$t("vehicleshistory-tbl-name"),
        align: "left",
        sortable: true,
        value: "name"
      },

      {
        text: this.$t("vehicleshistory-tbl-currentdriver"),
        align: "left",
        sortable: true,
        value: "driver_name"
      }
    ];
  },

  watch: {
    options: {
      handler() {
        this.fetchVehiclesHistory();
      },
      deep: true
    },

    filters: {
      handler() {
        clearTimeout(this._debounceTimer);
        this._debounceTimer = setTimeout(() => {
          if (this.options.page != 1) this.options.page = 1;
          else this.fetchVehiclesHistory();
        }, 500);
      },
      deep: true
    }
  },

  components: {
    FiltersBar
  },

  methods: {
    onFiltersLoading() {
      this.loadingFilters = true;
    },

    onFiltersLoaded() {
      this.loadingFilters = false;
    },

    getGravatar: function(item) {
      if (!item.gravatar) return null;
      return "data:image/jpeg;base64," + item.gravatar;
    },

    getUsername: function(item) {
      return item.name;
    },
    buildGravatar: function(item) {
      return (
        "<img style='width:48px; border:1px solid black; border-radius: 50%;' src='data:image/jpeg;base64," +
        item.gravatar +
        "'>"
      );
    },

    checkGravatar: function(item) {
      if (!item.gravatarFetched) {
        item.gravatarFetched = true;
        AvatarServices.fetchAvatar(item.event_user_id)
          .then(result => {
            item.first_name = Object.assign(item.first_name);
            item.gravatar = result.avatar;
          })
          .catch(err => {
            console.log(err);
          });
      }
      return true;
    },

    getProfileLetters: function(user) {
      if (user.first_name && user.last_name) {
        if (user.first_name.length && user.last_name.length) {
          return (
            user.first_name.toUpperCase()[0] + user.last_name.toUpperCase()[0]
          );
        }
      } else if (user.first_name && user.first_name.length >= 2) {
        return (
          user.first_name.toUpperCase()[0] + user.first_name.toUpperCase()[1]
        );
      } else if (user.first_name && user.first_name.length >= 1) {
        return (
          user.first_name.toUpperCase()[0] + user.first_name.toUpperCase()[0]
        );
      } else if (user.last_name && user.last_name.length >= 2) {
        return (
          user.last_name.toUpperCase()[0] + user.last_name.toUpperCase()[1]
        );
      } else if (user.last_name && user.last_name.length >= 1) {
        return (
          user.last_name.toUpperCase()[0] + user.last_name.toUpperCase()[0]
        );
      }

      return "";
    },

    fetchVehiclesHistory() {
      this.loadingVehiclesù = true;
      this.totalVehicles = 0;
      return new Promise((resolve, reject) => {
        let ctx = Object.assign({}, this.options);
        ctx.currentPage = ctx.page;
        ctx.perPage = ctx.itemsPerPage != -1 ? ctx.itemsPerPage : null;
        ctx.filter = this.filter;
        ctx.sortBy = ctx.sortBy[0];
        ctx.sortDesc = ctx.sortDesc[0];

        ctx.filterByUserId = this.filters.filterByUserId;
        ctx.filterByPeriod = this.filters.filterByPeriod;
        ctx.filterByCustomDateStart = this.filters.filterByCustomDateStart;
        ctx.filterByCustomDateEnd = this.filters.filterByCustomDateEnd;
        ctx.filterByCourierId = this.filters.filterByCourierId;
        ctx.filterByVehicleId = this.filters.filterByVehicleId;

        vehiclesManager
          .fetchVehiclesHistory(ctx)
          .then(result => {
            this.totalVehicles = result.totalCount;
            this.vehicles = result.items;
            this.loadingVehicles = false;
            resolve(result);
          })
          .catch(err => {
            this.loadingVehicles = false;
            console.log(err);
            reject(err);
          });
      });
    }
  }
};
</script>
